import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Page from "./pages/Page";
import NewsPage from "./pages/NewsPage";
import SummerPage from "./pages/SummerPage";
import StaffPage from "./pages/StaffPage";
import Frontpage from "./pages/Frontpage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "antd/dist/antd.css";

const App = (props) => {
  return (
    <>
      <Header></Header>

      <div style={{ minHeight: "calc(100vh - 135px)", display: "flex" }}>
        <BrowserRouter>
          <Switch>
            <div style={{ flex: 1 }}>
              <Route exact path="/" component={Frontpage} />

              <Route
                exact
                path={[
                  "/fimleikar/:slug/",
                  "/klifur/:slug/",
                  "/taekwondo/:slug/",
                  "/almenningsdeild/:slug/",
                  "/umOkkur/:slug/",
                ]}
                component={Page}
              />

              <Route exact path={["/frett/:slug"]} component={NewsPage} />
              <Route exact path={["/sumarnamskeid"]} component={SummerPage} />
              <Route exact path={["/starfsfolk"]} component={StaffPage} />
            </div>
          </Switch>
        </BrowserRouter>
      </div>

      <Footer back>
        <div style={{ textAlign: "center", color: "#fff" }}>
          <div>
            <div>
              <img style={{ width: 50 }} src="/images/fbjork.svg" alt="Logo" />
            </div>
          </div>

          <br></br>
          <div>Fimleikafélagið Björk</div>
          <span>KT: 550110-1130 | </span>
          <span>Sími: 416 0160</span>
          <br></br>
          <br />

          <h3 style={{ color: "white" }}>Opnunartími</h3>

          <p>Mán-Fim. 10:00-22:00</p>
          <p>Fös: 10:00-21:00</p>
          <p>Laug og sun: 10:00-17:00</p>

          <br></br>
          <div>
            <a href="https://be.contentful.com/login">Innskráning</a>
          </div>
        </div>
      </Footer>
    </>
  );
};

export default App;
