import React, { useEffect, useState } from "react";
import { Calendar as AntCalendar } from "antd";
import Axios from "axios";
import moment from "moment";

export default function Calendar(props) {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const { calendar } = props;
    const promises = calendar.items
      .filter((e) => e.status === "confirmed" && !!e.recurrence)
      .map((e) => {
        return Axios.get(
          `https://www.googleapis.com/calendar/v3/calendars/${calendar.calendarId}/events/${e.id}/instances?key=AIzaSyDtZFMp_v_KMq-GOu5pkbdm44i2hxIKgKM`
        );
      });

    const oneTimeEvents = calendar.items.filter(
      (e) => e.status === "confirmed" && !!!e.recurrence
    );

    Promise.all(promises).then((res) => {
      const list = res.map((r) => r.data.items);
      const joined = [].concat(...list, ...oneTimeEvents);

      setEvents(joined);
    });
  }, [props]);

  return (
    <>
      <AntCalendar
        loading={false}
        locale={{ lang: { locale: "is_IS", month: "Mánuður", year: "Ár" } }}
        onChange={(_moment) => {
          // this.onCalendarChange(_moment);
        }}
        // onSelect={(moment_date) => {
        //   if (this.props.onSelect) {
        //     return this.props.onSelect(sessions, moment_date);
        //   }

        //   const found = sessions.filter((session) =>
        //     moment(session.date).isSame(moment_date, "day")
        //   );
        //   if (found.length === 1)
        //     window.top.location.href = "/timi/" + found[0].uuid + "/";
        // }}
        dateCellRender={(moment_date) => {
          const e = events.filter(
            (evnt) =>
              moment_date.isSame(evnt.start.date, "day") &&
              evnt.status === "confirmed"
          );

          if (e.length) {
            return e.map((_e, key) => {
              const start = moment(_e.start.date).format("HH:mm");
              const end = moment(_e.end.date).format("HH:mm");
              return (
                <div key={key}>
                  <div style={{ fontSize: 12 }}>
                    <strong>{_e.summary}</strong>
                  </div>

                  {moment(_e.start.date).format("lll") ===
                  moment(_e.end.date).format("lll") ? (
                    <div>
                      {start}-{end}
                    </div>
                  ) : e.length > 1 ? (
                    <hr></hr>
                  ) : (
                    <></>
                  )}
                </div>
              );
            });
          } else {
            return;
          }
        }}
      ></AntCalendar>
    </>
  );
}
