import React, { useEffect, useState } from "react";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { useLocation } from "react-router-dom";
import RichTextRender from "../components/RichTextRender/RichTextRender";
import moment from "moment";
import "moment/locale/is";
import Cover from "../components/Cover";
import FbjorkMenu from "../components/FbjorkMenu";
import { Alert } from "antd";
moment.locale("is");

const NewsPage = ({ match }) => {
  const location = useLocation();
  const [content, setContent] = useState();
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    const pathname = location.pathname;
    const chunks = pathname.split("/");
    const slug = chunks[2];

    const _preview = (location.search || "").includes("preview=true");
    setPreview(_preview);

    const token = _preview
      ? "VE0SZlRkn6MOIVnO32rTqVj8zbC1S9ElV53RGXY78ss"
      : "Rzfvq8y68WQHSB1dISLvhghQrzBFxrwAMF-d3LkktJY";

    const client = new ApolloClient({
      uri: `https://graphql.contentful.com/content/v1/spaces/sypxagk7c4m0/environments/master?access_token=${token}`,
      cache: new InMemoryCache(),
    });

    client
      .query({
        query: gql`
        query {
          newsCollection(preview: ${_preview}, where: {slug: "${slug}"}, limit: 1){
            items{
              image {
                url
              }
              text {
                json
                links{
                  assets{
                    block {
                      sys{
                        id
                      }
                      url
                    }
                  }
                }
              }
              title
              date
              slug
              sys {
                firstPublishedAt
              }
            }
          }
        }
      `,
      })
      .then((res) => {
        console.log(res.data.newsCollection.items[0]);
        setContent(res.data.newsCollection.items[0]);
      });
  }, [location.pathname, location.search]);

  return (
    <div>
      <Cover></Cover>
      <div className="pageWrapper">
        <FbjorkMenu></FbjorkMenu>
        <div className="page">
          {content && (
            <>
              {preview && (
                <>
                  <Alert
                    type="warning"
                    message='Ath - þetta er sýnishorn. Gerðu "publish" til þess að birta síðuna'
                  ></Alert>
                  <br />
                </>
              )}
              <h1>{content.title}</h1>
              <div>
                {moment(content.date || content.sys.firstPublishedAt).format(
                  "ll"
                )}
              </div>

              <RichTextRender
                links={content.text.links}
                content={content.text.json}
              ></RichTextRender>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsPage;
