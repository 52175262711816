import React, { useState, useEffect } from "react";
import HeroSection from "./HeroSection";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const client = new ApolloClient({
  uri: "https://graphql.contentful.com/content/v1/spaces/sypxagk7c4m0/environments/master?access_token=Rzfvq8y68WQHSB1dISLvhghQrzBFxrwAMF-d3LkktJY",
  cache: new InMemoryCache(),
});

export default function Cover() {
  const [activeCoverPhoto, setActiveCoverPhoto] = useState("");
  const [coverPhotos, setCoverPhotos] = useState([]);

  useEffect(() => {
    if (!activeCoverPhoto) {
      setActiveCoverPhoto(coverPhotos[0]);
    } else {
      setTimeout(() => {
        const activeCoverPhotoIndex = coverPhotos.findIndex(
          (coverPhoto) => coverPhoto === activeCoverPhoto
        );

        if (coverPhotos[activeCoverPhotoIndex + 1]) {
          setActiveCoverPhoto(coverPhotos[activeCoverPhotoIndex + 1]);
        } else {
          setActiveCoverPhoto(coverPhotos[0]);
        }
      }, 8000);
    }
  }, [activeCoverPhoto, coverPhotos]);

  useEffect(() => {
    client
      .query({
        query: gql`
          query {
            frontpage(id: "2NeeOMjq8QsLmBff47G5i0") {
              coverPhotosCollection {
                items {
                  url
                }
              }
            }
          }
        `,
      })
      .then((res) => {
        setCoverPhotos(res.data.frontpage.coverPhotosCollection.items);
      });
  }, []);
  return (
    <HeroSection
      vheight={45}
      backgroundImage={activeCoverPhoto ? activeCoverPhoto.url + "?w=1200" : ""}
      style={{
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      innerContainerStyle={{
        background: "rgba(0, 111, 187, 0.67)",
        textAlign: "center",
      }}
    >
      <img
        src="/images/logo.svg"
        alt="Logo"
        style={{ width: "10%", minWidth: 125 }}
      />
    </HeroSection>
  );
}
