import React, { useState, useEffect } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Typography } from "antd";

const { Paragraph } = Typography;

export default function RichTextRender(props) {
  const [content, setContent] = useState();

  useEffect(() => {
    setContent(
      documentToReactComponents(props.content, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
            const assetId = node.data.target.sys.id;

            if (props.links) {
              const asset = props.links.assets.block.find(
                (block) => block.sys.id === assetId
              );

              const url = asset.url + "?w=800";

              return (
                <img
                  alt={"Mynd"}
                  src={url}
                  style={{
                    maxWidth: 600,
                    maxHeight: 450,
                  }}
                />
              );
            }

            if (props.includes && props.includes.Asset) {
              const asset = props.includes.Asset.find(
                (asset) => asset.sys.id === assetId
              );
              const file = asset.fields.file;
              const title = asset.fields.title;

              return file.contentType.includes("image") ? (
                <div
                  style={{
                    backgroundImage: `url(${file.url})`,
                    paddingBottom: "50%",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    maxWidth: "900px",
                    margin: "auto",
                  }}
                ></div>
              ) : (
                <a href={file.url} target="_blank" rel="noreferrer noopener">
                  {title}
                </a>
              );
            } else {
              return <></>;
            }
          },
        },
      })
    );
  }, [props]);

  return content ? (
    <Paragraph ellipsis={props.ellipsis} className={props.className || ""}>
      {content}
    </Paragraph>
  ) : (
    <></>
  );
}
