import React, { useEffect, useState } from "react";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { useLocation } from "react-router-dom";
import RichTextRender from "../components/RichTextRender/RichTextRender";
import moment from "moment";
import "moment/locale/is";
import Cover from "../components/Cover";
import FbjorkMenu from "../components/FbjorkMenu";
moment.locale("is");

const client = new ApolloClient({
  uri: "https://graphql.contentful.com/content/v1/spaces/sypxagk7c4m0/environments/master?access_token=Rzfvq8y68WQHSB1dISLvhghQrzBFxrwAMF-d3LkktJY",
  cache: new InMemoryCache(),
});

const NewsPage = ({ match }) => {
  const location = useLocation();
  const [content, setContent] = useState();

  useEffect(() => {
    if (!content) {
      client
        .query({
          query: gql`
            query {
              summerCollection(limit: 1) {
                items {
                  titill
                  texti {
                    json
                    links {
                      assets {
                        block {
                          sys {
                            id
                          }
                          url
                        }
                      }
                    }
                  }
                  active
                }
              }
            }
          `,
        })
        .then((res) => {
          setContent(res.data.summerCollection.items[0]);
        });
    }
  }, [location.pathname, content]);

  return (
    <div>
      <Cover></Cover>

      <div className="pageWrapper">
        <FbjorkMenu></FbjorkMenu>
        <div className="page">
          {content && (
            <>
              <h1>{content.titill}</h1>

              <RichTextRender
                links={content.texti.links}
                content={content.texti.json}
              ></RichTextRender>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsPage;
