import React, { useEffect, useState } from "react";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import Cover from "../components/Cover";
import FbjorkMenu from "../components/FbjorkMenu";

const client = new ApolloClient({
  uri: "https://graphql.contentful.com/content/v1/spaces/sypxagk7c4m0/environments/master?access_token=Rzfvq8y68WQHSB1dISLvhghQrzBFxrwAMF-d3LkktJY",
  cache: new InMemoryCache(),
});

export default function Staff() {
  const [staff, setStaff] = useState([]);

  useEffect(() => {
    if (!staff.length) {
      client
        .query({
          query: gql`
            query {
              staffCollection {
                items {
                  name
                  image {
                    url
                  }
                  job
                  email
                  phone
                  order
                }
              }
            }
          `,
        })
        .then((res) => {
          const ordered = res.data.staffCollection.items
            .slice()
            .sort((a, b) => (a.order || 99) - (b.order || 99));
          setStaff(ordered);
        });
    }
  }, [staff]);

  return (
    <div>
      <Cover></Cover>
      <div className="pageWrapper">
        <FbjorkMenu></FbjorkMenu>
        <div className="page">
          {staff && (
            <>
              <h1>Starfsfólk</h1>

              <div className="staff">
                {staff.map((person, key) => {
                  return (
                    <div className="person" key={key}>
                      <div
                        style={{
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundImage: person.image
                            ? `url(${person.image.url}?w=350)`
                            : "",
                        }}
                        className="staffImg"
                      ></div>
                      <h4 style={{ marginTop: 10 }}>{person.name}</h4>
                      <div>{person.job}</div>
                      {person.email && (
                        <div>
                          <a href={`mailto:${person.email}`}>{person.email}</a>
                        </div>
                      )}
                      {person.phone && (
                        <div>
                          <a href={`tel:${person.phone}`}>{person.phone}</a>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
