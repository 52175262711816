import React from "react";
import { Layout } from "antd";
import { PhoneOutlined, MenuOutlined } from "@ant-design/icons";

const { Header: AntHeader } = Layout;

export default function Header() {
  return (
    <AntHeader className="header">
      <div>
        <a className="logo" href="/">
          <img src="/images/logo.svg" alt="Logo" />
        </a>
        <span className="header-title">Fimleikafélagið Björk</span>
      </div>

      <div style={{ display: "flex" }}>
        <div
          style={{
            color: "#fff",
            position: "relative",

            top: 10,
            fontSize: 18,
            fontWeight: "lighter",
            textAlign: "right",
          }}
        >
          <div style={{ lineHeight: "30px" }}>
            <PhoneOutlined /> 416 0160
          </div>

          <div style={{ lineHeight: "30px" }}>fbjork@fbjork.is</div>
        </div>
        <div className="mobileMenuButton">
          <MenuOutlined
            onClick={() => {
              const visible = document.body.classList.contains(
                "mobile-menu-visible"
              );
              if (!visible) {
                document.body.classList.add("mobile-menu-visible");
                window.scrollTo(0, 0);
              } else {
                document.body.classList.remove("mobile-menu-visible");
              }
            }}
            style={{
              color: "#fff",
              fontSize: 37,
              position: "relative",
              top: 18,
              marginLeft: 20,
              marginRight: 10,
              cursor: "pointer",
            }}
          />
        </div>
      </div>

      {/*   <Menu className="main_menu" theme="dark" mode="horizontal">
        <div
          style={{
            marginLeft: "auto",
            backgroundColor: "transparent",
            cursor: "auto",
            display: "flex",
          }}
        >
          <Menu.Item
            style={{
              marginLeft: "auto",
              backgroundColor: "transparent",
              cursor: "auto",
            }}
          >
            <a style={{ color: "#fff", background: "none" }} href="/nyskra/">
              Hello world
            </a>
          </Menu.Item>
          <Menu.Item
            style={{
              marginLeft: "auto",
              backgroundColor: "transparent",
              cursor: "auto",
            }}
          >
            <a style={{ color: "#fff" }} href="/nyskra/">
              Hello world
            </a>
          </Menu.Item>
        </div>
      </Menu> */}
    </AntHeader>
  );
}
