import React, { useEffect, useState } from "react";
import HeroSection from "../components/HeroSection";
import Cover from "../components/Cover";
import { Card, Tabs, Typography, Modal, Button, message } from "antd";
import Calendar from "../components/Calendar/Calendar";
import Axios from "axios";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import RichTextRender from "../components/RichTextRender/RichTextRender";
import moment from "moment";
import { Link } from "react-router-dom";
import "moment/locale/is";
import FbjorkMenu from "../components/FbjorkMenu";
moment.locale("is");

const client = new ApolloClient({
  uri: "https://graphql.contentful.com/content/v1/spaces/sypxagk7c4m0/environments/master?access_token=Rzfvq8y68WQHSB1dISLvhghQrzBFxrwAMF-d3LkktJY",
  cache: new InMemoryCache(),
});

const { TabPane } = Tabs;
const { Title } = Typography;

const newsItemCount = 6;

export default function Frontpage() {
  const [calendars, setCalendars] = useState([]);
  const [news, setNews] = useState();

  const [announcements, setAnnouncements] = useState([]);
  const [modalContent, setModalContent] = useState();
  const [noMoreNews, setNoMoreNews] = useState(false);
  const [newsLoading, setNewsLoading] = useState(false);
  const [sponsors, setSponsors] = useState([]);

  const getContentCategory = (category) =>
    Axios.get(
      `https://cdn.contentful.com/spaces/sypxagk7c4m0/environments/master/entries/?content_type=${category}&access_token=Rzfvq8y68WQHSB1dISLvhghQrzBFxrwAMF-d3LkktJY`
    );

  useEffect(() => {
    client
      .query({
        query: gql`
          query {
            frontpage(id: "2NeeOMjq8QsLmBff47G5i0") {
              slug
              sponsorsCollection {
                items {
                  url
                }
              }
            }

            announcementsCollection {
              items {
                isUrl
                url
                title
                body {
                  json
                }
              }
            }

            newsCollection(order: [sys_firstPublishedAt_DESC, date_DESC], limit: ${newsItemCount} ) {
              items {
                image {
                  url
                }
                text {
                  json
                }
                title
                slug
                sys {
                  firstPublishedAt
                }
              }
            }
          }
        `,
      })
      .then((res) => {
        setNews(res.data.newsCollection.items);

        setAnnouncements(res.data.announcementsCollection.items);
      });

    client
      .query({
        query: gql`
          query {
            sponsorsCollection(limit: 999) {
              items {
                image {
                  url
                }
                url
              }
            }
          }
        `,
      })
      .then((res) => {
        setSponsors(res.data.sponsorsCollection.items);
      });

    getContentCategory("googleCalendars").then((rsp) => {
      const calendarIds = rsp.data.items.map((item) => item.fields.calendarId);

      const p = calendarIds.map((id) =>
        Axios.get(
          `https://www.googleapis.com/calendar/v3/calendars/${id}/events?key=AIzaSyDtZFMp_v_KMq-GOu5pkbdm44i2hxIKgKM`
        )
      );

      Promise.all(p).then((res) =>
        setCalendars(
          res.map((r, i) => {
            const data = r.data;
            data.calendarId = calendarIds[i];
            return data;
          })
        )
      );
    });
  }, []);
  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1 }}>
        <Cover></Cover>

        <HeroSection innerContainerStyle={{ padding: 0 }} vheight={50}>
          <div className="frontMainContent">
            <FbjorkMenu></FbjorkMenu>
            <div id="newsItems">
              {news &&
                news.length &&
                news.map((article, key) => {
                  /*         const created = moment(article.sys.firstPublishedAt).format(
                  "ll"
                );
                const imageId = article.image.url; */
                  /*   console.log(
                  `https://images.ctfassets.net/${spaceId}/6iY70h0fBaRfGP4WrJEkE0/ce30743c707bfddd985d06bd288e6ea8/id3.jpg?w=500`
                ); */

                  return (
                    <Link
                      className="newsThumb"
                      key={key}
                      to={`/frett/${article.slug}`}
                    >
                      <div
                        style={{
                          background: `url(${article.image.url}?w=500)`,
                          paddingBottom: "50%",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></div>

                      <div style={{ margin: 15 }}>
                        <Title style={{ fontSize: 18, marginTop: 15 }}>
                          {article.title}
                        </Title>
                      </div>
                    </Link>
                  );
                })}
            </div>

            <div style={{ minWidth: 280, paddingTop: 20 }}>
              {announcements.map((announcement, key) => {
                return announcement.isUrl ? (
                  <a
                    className="announcement"
                    key={key}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={announcement.url}
                  >
                    {announcement.title}
                  </a>
                ) : (
                  <div
                    className="announcement"
                    onClick={() => {
                      setModalContent(announcement);
                    }}
                    key={key}
                  >
                    {announcement.title}
                  </div>
                );
              })}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#fff",
                }}
              >
                <Card
                  title="Haukahraun 1, 220 Hafnarfjörður"
                  style={{
                    maxWidth: 900,
                    width: "100%",
                  }}
                  className="frontpage_map"
                >
                  <div className="map-responsive">
                    <iframe
                      title="google_maps"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1744.4432988026704!2d-21.948416983731523!3d64.07312128356207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2ac1a2e20b1dc443!2zw43DvnLDs3R0YW1pw7BzdMO2w7BpbiBCasO2cms!5e0!3m2!1sen!2sus!4v1617196345893!5m2!1sen!2sus&language=is"
                      width="300"
                      height="300"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                    ></iframe>
                  </div>
                </Card>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {!noMoreNews && (
              <Button
                loading={newsLoading}
                style={{
                  margin: 20,
                }}
                onClick={() => {
                  setNewsLoading(true);

                  client
                    .query({
                      query: gql`
                        query {
                          newsCollection(order: [sys_firstPublishedAt_DESC, date_DESC], limit: ${newsItemCount}, skip: ${news.length}) {
                            items {
                              image {
                                url
                              }
                              text {
                                json
                              }
                              title
                              slug
                              sys {
                                firstPublishedAt
                              }
                            }
                          }
                        }
                    `,
                    })
                    .then((res) => {
                      setNews([...news, ...res.data.newsCollection.items]);
                      setNewsLoading(false);

                      if (!res.data.newsCollection.items.length) {
                        setNoMoreNews(true);
                        message.error("Það eru ekki fleiri fréttir");
                      }
                    });
                }}
              >
                Sækja fleiri fréttir
              </Button>
            )}
          </div>
        </HeroSection>

        {!!calendars.length && (
          <HeroSection vheight={50}>
            <div className="text-center">
              <Title>Viðburðadagatal</Title>
            </div>

            <div style={{ margin: "auto", maxWidth: 1200 }}>
              <Tabs defaultActiveKey="1" centered onChange={() => {}}>
                {calendars.length &&
                  calendars.map((calendar, key) => (
                    <TabPane tab={calendar.summary} key={key}>
                      <Calendar calendar={calendar}></Calendar>
                    </TabPane>
                  ))}
              </Tabs>
            </div>
          </HeroSection>
        )}

        {!!sponsors.length > 0 && (
          <>
            <div className="text-center">
              <Title>Styrktaraðilar</Title>
            </div>

            <div
              style={{ margin: "auto", maxWidth: 1200, textAlign: "center" }}
            >
              <HeroSection vheight={20}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  {sponsors.map((sponsor, key) => {
                    return (
                      <div key={key}>
                        <a
                          target="_blank"
                          rel="noreferrer noopener"
                          href={sponsor.url ? sponsor.url : "#"}
                          style={{ margin: 20 }}
                        >
                          <img
                            alt="Styrktar logo"
                            style={{ maxWidth: 200, borderRadius: 5 }}
                            src={sponsor.image.url}
                          />
                        </a>
                      </div>
                    );
                  })}
                </div>
              </HeroSection>
            </div>
          </>
        )}
      </div>

      {modalContent && (
        <Modal
          visible={!!modalContent}
          title={modalContent.title}
          footer={null}
          onCancel={() => {
            setModalContent();
          }}
        >
          <RichTextRender content={modalContent.body.json}></RichTextRender>
        </Modal>
      )}
    </div>
  );
}
