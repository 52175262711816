import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getContentTypeBySlug } from "./../utils/getContent";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import FbjorkMenu from "../components/FbjorkMenu";
import Cover from "../components/Cover";

const Page = ({ match }) => {
  const location = useLocation();
  const [content, setContent] = useState();

  useEffect(() => {
    const pathname = location.pathname;
    const chunks = pathname.split("/");

    const category = chunks[1];
    const slug = chunks[2];

    getContentTypeBySlug(category, slug).then((rsp) => {
      setContent(rsp.data);
    });
  }, [location]);

  return (
    <div>
      <Cover></Cover>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <FbjorkMenu></FbjorkMenu>
        <div className="page">
          {content && (
            <>
              <h1>{content.items[0].fields.titill}</h1>
              {documentToReactComponents(content.items[0].fields.texti, {
                renderNode: {
                  [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
                    const assetId = node.data.target.sys.id;
                    const asset = content.includes.Asset.find(
                      (asset) => asset.sys.id === assetId
                    );
                    const file = asset.fields.file;
                    const title = asset.fields.title;

                    return file.contentType.includes("image") ? (
                      <img
                        src={file.url + "?w=1000"}
                        height={file.details.image.height}
                        width={file.details.image.width}
                        alt={file.description || "mynd"}
                        style={{
                          maxHeight: "70vh",
                          width: "auto",
                          height: "auto",
                          maxWidth: "70%",
                        }}
                      />
                    ) : (
                      <a
                        href={file.url}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {title}
                      </a>
                    );
                  },
                },
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Page;
