import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import Axios from "axios";

const categoryStrings = [
  "umOkkur",
  "fimleikar",
  "klifur",
  "taekwondo",
  "almenningsdeild",
];

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const getContentCategory = (category) =>
  Axios.get(
    `https://cdn.contentful.com/spaces/sypxagk7c4m0/environments/master/entries/?content_type=${category}&access_token=Rzfvq8y68WQHSB1dISLvhghQrzBFxrwAMF-d3LkktJY`
  );

export default function FbjorkMenu() {
  const [categories, setCategories] = useState([]);
  const [summerActive, setSummerActive] = useState(false);

  useEffect(() => {
    const categoryPromises = categoryStrings.map((category) =>
      getContentCategory(category)
    );

    Promise.all(categoryPromises).then((respones) => {
      const cats = respones.map((res, i) => {
        return {
          title: categoryStrings[i],
          items: res.data.items,
          includes: res.data.includes,
        };
      });

      setCategories(cats);
    });

    getContentCategory("summer").then((rsp) => {
      setSummerActive(rsp.data.items[0].fields.active);
    });
  }, []);
  return (
    <Menu
      mode="inline"
      onSelect={() => {
        document.body.classList.remove("mobile-menu-visible");
        window.scrollTo(0, 0);
      }}
      style={{
        width: 250,
      }}
    >
      <Menu.Item style={{ width: 250 }} key="forsida">
        <Link to="/">Forsíða</Link>
      </Menu.Item>
      {categories.map((category, key) => {
        return (
          <Menu.SubMenu
            key={key}
            title={
              category.title === "umOkkur"
                ? "Um okkur"
                : category.title === "almenningsdeild"
                ? "Foreldraráð"
                : capitalizeFirstLetter(category.title)
            }
          >
            <>
              {category.title === "umOkkur" && (
                <Menu.Item style={{ width: 250 }} key="starfsfolk">
                  <Link to="/starfsfolk/">Starfsfólk</Link>
                </Menu.Item>
              )}

              {category.items.map((content, i) => {
                return (
                  <Menu.Item style={{ width: 250 }} key={key + "_" + i}>
                    <Link to={`/${category.title}/${content.fields.slug}`}>
                      {content.fields.titill}
                    </Link>
                  </Menu.Item>
                );
              })}
            </>
          </Menu.SubMenu>
        );
      })}

      {summerActive && (
        <Menu.Item style={{ width: 250 }} key="summer">
          <Link to={`/sumarnamskeid`}>Sumarnámskeið</Link>
        </Menu.Item>
      )}

      <Menu.Item style={{ width: 250 }} key="salir">
        <a
          href="https://bjorksala.myshopify.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Salir til leigu
        </a>
      </Menu.Item>
    </Menu>
  );
}
