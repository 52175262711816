import Axios from "axios";

const spaceId = "sypxagk7c4m0";
const accessToken = "Rzfvq8y68WQHSB1dISLvhghQrzBFxrwAMF-d3LkktJY";

const getContentCategory = (category) =>
  Axios.get(
    `https://cdn.contentful.com/spaces/${spaceId}/environments/master/entries/?content_type=${category}&access_token=${accessToken}`
  );

export const getSingleEntry = (entryId) =>
  Axios.get(
    `https://cdn.contentful.com/spaces/${spaceId}/environments/master/entries/${entryId}?access_token=${accessToken}`
  );

export const getSingleAsset = (assetId) =>
  Axios.get(
    `https://cdn.contentful.com/spaces/${spaceId}/environments/master/assets/${assetId}?access_token=${accessToken}`
  );

export const getContentTypeBySlug = (contentTypeId, slug) => {
  return Axios.get(
    `https://cdn.contentful.com/spaces/${spaceId}/environments/master/entries?access_token=${accessToken}&content_type=${contentTypeId}&fields.slug=${slug}`
  );
};

export default getContentCategory;
